var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Messages', {
    attrs: {
      "parent-id": _vm.asset._id,
      "parent-type": "asset",
      "parent": _vm.asset,
      "accounts": _vm.accounts,
      "entity-id": _vm.asset.entityId,
      "asset-id": _vm.asset._id
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }