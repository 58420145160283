var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.messages.length > 0 ? _c('div', {
    staticClass: "messages list-group font-size-sm",
    class: {
      'inner-content-overflow': _vm.messages.length > 4
    }
  }, _vm._l(_vm.messages, function (message, index) {
    return _c('a', {
      key: index,
      staticClass: "message list-group-item list-group-item-action",
      class: {
        'message-active': _vm.selectedMessage._id === message._id,
        'opacity-50': message.status === 'closed',
        'bg-light': _vm.pinned
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('click-message', message);
        }
      }
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-xl-2"
    }, [_vm.getUserFromSub(message.userSub).picture ? _c('img', {
      staticClass: "img-avatar img-avatar48",
      attrs: {
        "src": _vm.getUserFromSub(message.userSub).picture,
        "alt": ""
      }
    }) : _vm._e(), message.replies.some(function (reply) {
      return reply.attachments && reply.attachments.length > 0;
    }) ? _c('div', {
      staticClass: "text-center text-muted mt-3"
    }, [_c('i', {
      staticClass: "fa fa-paperclip"
    })]) : _vm._e()]), _c('div', {
      staticClass: "col-xl-10"
    }, [_c('div', {
      staticClass: "d-flex align-items-start justify-content-between mb-3"
    }, [_c('div', [_c('p', {
      staticClass: "message-user font-size-h6 text-secondary mb-0"
    }, [_vm._v(_vm._s(_vm.getUserFromSub(message.userSub).name))]), _c('p', {
      staticClass: "message-title font-size-h5 font-w700 mb-0"
    }, [_vm._v(_vm._s(message.title))])]), _c('div', [_c('div', {
      staticClass: "text-right"
    }, [_c('span', {
      staticClass: "badge badge-info text-capitalize"
    }, [_vm._v(_vm._s(message.type))]), _c('br'), _c('span', {
      staticClass: "badge badge-success text-capitalize"
    }, [_vm._v(_vm._s(message.category))]), _c('br'), message.dueDate ? _c('span', {
      staticClass: "badge badge-danger text-capitalize"
    }, [_c('i', {
      staticClass: "fa fa-clock"
    }), _vm._v(" " + _vm._s(_vm._f("date")(message.dueDate, 'DD/MM/YYYY HH:mm')))]) : _vm._e()])])]), _c('div', {
      staticClass: "d-flex align-items-start justify-content-between"
    }, [_c('p', {
      staticClass: "message-preview text-muted mb-2"
    }, [_vm._v(" " + _vm._s(message.replies[message.replies.length - 1].content && message.replies[message.replies.length - 1].content.slice(0, 32)) + " ")]), _c('div', {
      staticClass: "text-right mb-1"
    }, [_c('span', {
      staticClass: "text-uppercase font-w700 mr-2",
      class: {
        'text-muted': message.status !== 'open',
        'text-success': message.status === 'open'
      }
    }, [_vm._v(_vm._s(message.status))])])])])])]);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }