<template>
  <Messages :parent-id="asset._id" parent-type="asset" :parent="asset" :accounts="accounts" :entity-id="asset.entityId" :asset-id="asset._id" />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Messages from '@/components/Messages';

export default {
  name: 'AssetMessages',
  components: {
    Messages
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts'
    })
  },
  async mounted() {
    await this.listAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1' } } });
  },
  methods: {
    ...mapActions({
      listAccounts: 'asset/accounts'
    })
  }
};
</script>
