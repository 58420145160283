<template>
  <div>
    <div v-if="messages.length > 0" class="messages list-group font-size-sm" :class="{ 'inner-content-overflow': messages.length > 4 }">
      <a
        v-for="(message, index) in messages"
        :key="index"
        class="message list-group-item list-group-item-action"
        :class="{
          'message-active': selectedMessage._id === message._id,
          'opacity-50': message.status === 'closed',
          'bg-light': pinned
        }"
        href="#"
        @click.prevent="$emit('click-message', message)"
      >
        <div class="row">
          <div class="col-xl-2">
            <img
              v-if="getUserFromSub(message.userSub).picture"
              class="img-avatar img-avatar48"
              :src="getUserFromSub(message.userSub).picture"
              alt=""
            />
            <div v-if="message.replies.some(reply => reply.attachments && reply.attachments.length > 0)" class="text-center text-muted mt-3">
              <i class="fa fa-paperclip"></i>
            </div>
          </div>
          <div class="col-xl-10">
            <div class="d-flex align-items-start justify-content-between mb-3">
              <div>
                <p class="message-user font-size-h6 text-secondary mb-0">{{ getUserFromSub(message.userSub).name }}</p>
                <p class="message-title font-size-h5 font-w700 mb-0">{{ message.title }}</p>
              </div>
              <div>
                <div class="text-right">
                  <span class="badge badge-info text-capitalize">{{ message.type }}</span>

                  <br />
                  <span class="badge badge-success text-capitalize">{{ message.category }}</span>
                  <br />

                  <span v-if="message.dueDate" class="badge badge-danger text-capitalize"
                    ><i class="fa fa-clock"></i> {{ message.dueDate | date('DD/MM/YYYY HH:mm') }}</span
                  >
                </div>
              </div>
            </div>

            <div class="d-flex align-items-start justify-content-between">
              <p class="message-preview text-muted mb-2">
                {{ message.replies[message.replies.length - 1].content && message.replies[message.replies.length - 1].content.slice(0, 32) }}
              </p>

              <div class="text-right mb-1">
                <span
                  :class="{ 'text-muted': message.status !== 'open', 'text-success': message.status === 'open' }"
                  class="text-uppercase font-w700 mr-2"
                  >{{ message.status }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MessagesInbox',
  props: {
    messages: {
      type: Array,
      required: true
    },
    getUserFromSub: {
      type: Function,
      required: true
    },
    selectedMessage: {
      type: Object,
      required: true
    },
    pinned: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>
